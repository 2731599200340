import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HOME, PROCESS, BULLETINS, REPORTING, REWARDS, LOGIN, MYISSUES, CREATE_REPORTING, REPORT_SUBMIT } from "navigation/routes";
import { SelectLangCdSet } from "redux/action";
import { Dropdown } from "react-bootstrap";
import ThemeContext from "../theme-context";
import { Cookies } from 'react-cookie';
import { LogOut } from "redux/action";
import * as LangUtils from "lang/LangUtils";
import rstCd from "utils/resultCode";
import "./Header.scss";
import LOGO from "assets/common/img_logo.png";

function Header(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const cookies = new Cookies();
  const [email, setEmail] = useState(""); //user email
  const [userName, setUserName] = useState(""); //user name
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    if(props.isLogin) {
      let loginInfo = cookies.get("userInfo").userInfo;
      let userEmail = loginInfo.email;

      setEmail(userEmail);
      getUserName(userEmail);
    }

    dispatch(
      SelectLangCdSet({})
    ).then((response) => {
        if (response.payload && response.payload.data.resultCode === rstCd.S0000) {
            var data = response.payload.data.res;
            props.setLangData(data);
        } else {
          props.setLangData([{idx: 1, langCd: "en", langText: "English"}]);
        }
    })
  }, []);
  
  useEffect(() => {
    if(props.isLogin) {
      let loginInfo = cookies.get("userInfo").userInfo;
      let userEmail = loginInfo.email;
      let userAccessToken = loginInfo.accessToken;

      setUserToken(userAccessToken);
      getUserName(userEmail);
    }
  }, [props.isLogin]);

  const handleChangeLanguage=(code)=> {
    i18n.changeLanguage(code);
    i18n.emit("languageChanged");
    localStorage.setItem("language", code);
  };

  let getUserName=(email)=> {
    let text = email.substr(0, 2);
    setUserName(text);
  };

  const signInOnClick=()=> {
    document.getElementById('navbarCollapse').classList.remove('show');
    
    history.push({
      pathname: LOGIN,
      state: { path: history.location.pathname },
    });
  };

  const signOutOnClick=()=> {
    document.getElementById('navbarCollapse').classList.remove('show');

    dispatch(
      LogOut({
          accessToken: userToken,
          userId: email
        })
    ).then((response)=> {
        let resCode = response.payload.data.resultCode;
        if(response.payload && resCode === rstCd.S0000) {
          var data = response.payload.data.res;
          props.setIsLogin(false);
          cookies.remove('userInfo');
          if(history.location.pathname === MYISSUES || history.location.pathname === CREATE_REPORTING || history.location.pathname === REPORT_SUBMIT) {
            history.push(HOME);
          }
        } else {
          if(response.payload && resCode === rstCd.E8003) {
            props.setIsLogin(false);
            cookies.remove('userInfo');

            if(history.location.pathname === MYISSUES || history.location.pathname === CREATE_REPORTING || history.location.pathname === REPORT_SUBMIT) {
              history.push(HOME);
            }
          } else {
            alert(response.payload.data.message);
          }
        }
    })
  };

  const onClickPath=(_path)=> {
    document.getElementById('navbarCollapse').classList.remove('show');

    history.push({
      pathname: _path,
      state: { path: _path },
    });
  };

  const myIssueOnclick=()=> {
    document.getElementById('navbarCollapse').classList.remove('show');
    history.push({
      pathname: MYISSUES, state: {path : MYISSUES}
    });
  };

  return (
    <header
      className={
        "header-navbar navbar navbar-expand-lg align-items-center w-100 position-absolute z-index-10 navbar-" +
        theme
      }
    >
      <div className="position-relative container-fluid">
        {/* LOGO */}
        <a
          className="navbar-brand mr-auto"
          href="/"
          aria-label="Click to go back to Homepage"
        >
          <img
            src={LOGO}
            alt=""
            className="img-responsive"
            style={{ maxHeight: "50px" }}
          />
        </a>
        {/* NAVBAR */}
        <button
          className="navbar-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            {/* menu items */}
            {/* LG Response Process */}
            <li className="nav-item header-menu-process header-nav-text">
              <a onClick={()=>onClickPath(PROCESS)} className="nav-link">
              {t("response-process")}
              </a>
            </li>

            {/* LG Security Bulletin */}
            <li className="nav-item header-menu-reporting header-nav-text">
              <a onClick={()=>onClickPath(BULLETINS)} className="nav-link">
              {t("security-bulletins")}
              </a>
            </li>

            {/* Security Reporting */}
            <li className="nav-item header-menu-reporting header-nav-text">
              <a onClick={()=>onClickPath(REPORTING)} className="nav-link">
              {t("security-reporting")}
              </a>
            </li>

            {/* Rewards Program */}
            <li className="nav-item header-menu-rewards header-nav-text">
              <a onClick={()=>onClickPath(REWARDS)} className="nav-link">
              {t("rewards-program")}
              </a>
            </li>
          </ul>

          <Dropdown className={"lan-dropdown " + theme}>
            <Dropdown.Toggle id="dropdown-basic" className={"lan-btn " + theme}>
              {LangUtils.selectLangCdToLangText(props.langData)}
            </Dropdown.Toggle>

            <Dropdown.Menu className="lan-dropdown-menu">
              {props.langData.map((_lang, idx) => {
                if(LangUtils.selectLangCd(props.langData) != _lang.langCd) {
                  return (
                    <div key={"lan-dropdown-item"+idx}>
                      <Dropdown.Item className="lan-dropdown-item" href={"#/action-"+idx} onClick={()=>handleChangeLanguage(_lang.langCd)}>{_lang.langText}</Dropdown.Item>
                    </div>
                  );
                }
              })}
            </Dropdown.Menu>
          </Dropdown>

          {/* LOGIN BTN */}
          {props.isLogin ? 
            <div className="d-flex">
              <div className="dropdown header-user-dropdown">
                <button className="avatar-circle" id="userDropdownMenuButton" data-toggle="dropdown">{userName}</button>
                <div className="dropdown-menu header-user-dropdown-menu" aria-labelledby="userDropdownMenuButton">
                  <a className="dropdown-item" onClick={()=>history.push({pathname: MYISSUES, state: {path : MYISSUES}})}>{t("my-issues")}</a>
                  <a className="dropdown-item" onClick={signOutOnClick}>{t("sign-out")}</a>
                </div>
              </div>
              <div className="header-my-issues" onClick={()=>myIssueOnclick()}>{t("my-issues")}</div>
            </div>
            : 
            <div
              className="btn btn-sm btn-secondary mt-3 mt-lg-0 ml-lg-4 d-block d-lg-inline-block sign-in-btn"
              onClick={signInOnClick}
            >
              {t("sign-in")}
            </div>
          }
          {/* SIGN OUT BTN */}
          {props.isLogin ? 
            <div className="btn btn-sm btn-secondary mt-3 mt-lg-0 ml-lg-4 d-block d-lg-inline-block sign-out-btn" onClick={signOutOnClick}>{t("sign-out")}</div>
            : null
          }
        </div>
      </div>
    </header>
  );
}

export default Header;