import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PROCESS, BULLETINS, REPORTING, REWARDS, TERMS_CONDITION, PRIVACY_POLICY } from "navigation/routes";
import { SelectLangCdSet } from "redux/action";
import { Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import ThemeContext from "../theme-context";
import * as LangUtils from "lang/LangUtils";
import rstCd from "utils/resultCode";
import "./Footer.scss";

function Footer(props) {
  const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangeLanguage=(code)=> {
    i18n.changeLanguage(code);
    i18n.emit("languageChanged");
    localStorage.setItem("language", code);
  };

  const onClickPath=(_path)=> {
    history.push({
      pathname: _path,
      state: { path: _path },
    });
  };

  useEffect(() => {
    dispatch(
      SelectLangCdSet({})
    ).then((response) => {
        if (response.payload && response.payload.data.resultCode === rstCd.S0000) {
            var data = response.payload.data.res;
            props.setLangData(data);
        } else {
          props.setLangData([{idx: 1, langCd: "en", langText: "English"}]);
        }
    })
  }, []);

  return (
    <footer className="bg-primary text-white small">
      <div className="border-gray py-3">
        <div className="container">
          <div className="row justify-content-between small">
            <div className="col-auto">
              <h4>{t("site-map")}</h4>
              <a onClick={()=>onClickPath(PROCESS)} className="text-reset footer-text">
              {t("response-process")}
              </a>{" "}
              |{" "}
              <a onClick={()=>onClickPath(BULLETINS)} className="text-reset footer-text">
              {t("security-bulletins")}
              </a>{" "}
              |{" "}
              <a onClick={()=>onClickPath(REPORTING)} className="text-reset footer-text">
              {t("security-reporting")}
              </a>{" "}
              |{" "}
              <a onClick={()=>onClickPath(REWARDS)} className="text-reset footer-text">
              {t("rewards-program")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="border-top border-gray py-3">
        <div className="container">
          <div className="row justify-content-between small">
            <div className="col-auto footer-col footer-policy">
              <a onClick={()=>onClickPath(TERMS_CONDITION)} className="text-reset footer-text">
                {t("terms-condition")}
              </a>{" "}
              |{" "}
              <a onClick={()=>onClickPath(PRIVACY_POLICY)} className="text-reset footer-text">
                {t("privacy-policy")}
              </a>
            </div>
            <div className="col-auto footer-col text-center">
              <a
                data-scroll
                href="#"
                className="d-inline-block text-reset"
                title="Up to the top"
                style ={{ scrollBehavior: "smooth"}}
              >
                <svg
                  width={20}
                  height={20}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <title>arrow-button-up-1</title>
                  <path d="M12,10.416a2.643,2.643,0,0,1,1.875.775l9.541,9.541a1.768,1.768,0,0,1-2.5,2.5l-8.739-8.739a.25.25,0,0,0-.354,0L3.084,23.232a1.768,1.768,0,0,1-2.5-2.5l9.541-9.541A2.643,2.643,0,0,1,12,10.416Z" />
                  <path d="M12,.25a2.643,2.643,0,0,1,1.875.775l9.541,9.541a1.768,1.768,0,0,1-2.5,2.5L12.177,4.327a.25.25,0,0,0-.354,0L3.084,13.066a1.768,1.768,0,0,1-2.5-2.5l9.541-9.541A2.643,2.643,0,0,1,12,.25Z" />
                </svg>
              </a>
            </div>
            <div className="col-auto footer-col">
              <span>{t("copyright")}</span>
            </div>

            <div className="col-auto footer-lan-dropdown-col">
              <div className="mb-2">
                <DropdownButton
                  as={ButtonGroup}
                  key={'up'}
                  className={"footer-lan-dropdown "}
                  id={`dropdown-button-drop-up footer-lan-dropdown-btn `}
                  drop={'up'}
                  variant={"secondary"}
                  title={LangUtils.selectLangCdToLangText(props.langData)}
                  >
                  {props.langData.map((_lang, idx) => {
                    if(LangUtils.selectLangCd(props.langData) != _lang.langCd) {
                      return (
                        <Dropdown.Item className="lan-dropdown-item" href={"#/action-"+idx} onClick={()=>handleChangeLanguage(_lang.langCd)} key={"footer-lan-dropdown-item"+idx}>{_lang.langText}</Dropdown.Item>
                      );
                    }
                  })}
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
