/* eslint-disable import/no-anonymous-default-export */

export default {
  // DOMAIN: "https://10.177.230.37:47901/", // 개발 서버
  // DOMAIN: "https://lgsec-test@lge.com:47901/", 
  DOMAIN: "https://lgsecurity.lge.com:47901/", // 운영 서버

  //login user
  SIGN_UP: "psrt/cog/cogSignUp.do",        //회원 가입
  SIGN_IN: "psrt/cog/cogSignIn.do",        //로그인
  LOG_OUT: "psrt/cog/cogSignOut.do",       //로그아웃
  EMAIL_CODE: "psrt/cog/cogVerCode.do",    //이메일 확인코드 인증
  CHANGE_PW: "psrt/cog/cogChangePwd.do",     //이메일 변경
  RESET_PW: "psrt/cog/cogResetPwd.do",     //비밀번호 초기화 요청
  UPDATE_PW: "psrt/cog/cogUpdatePwd.do",   //비밀번호 초기화 업데이트
  NEW_TOKEN: "psrt/cog/cogReqNewToken.do", //Token 재발급
  DELETE_USER: "psrt/cog/cogDeleteUser.do", //회원 탈퇴
  RESEND_CONF_CODE: "psrt/cog/cogResendConfirmCode.do", //인증 코드 재전송 요청


  //Bulletins
  BLT_TYPE_LIST_SELECT: "psrt/bltns/selectBltnsType.do", //Bulletins 타입 리스트 조회
  BLT_ALL_SMR: "psrt/bltns/selectBltnsAllSMR.do", //Bulletins SMR 전체 리스트 조회

  //Reports
  SELECT_REPORT: "psrt/report/selectReport.do", //Report 조회
  INSERT_REPORT: "psrt/report/insertReport.do", //Report 추가
  SELECT_REPORT_STATE: "psrt/report/selectReportState.do", //Report State 조회

  //Label
  SELECT_LABEL_SET: "psrt/label/selectLabelSet.do", //Label Set 조회

  SELECT_LANG_CD_SET: "psrt/admin/selectLangCd.do", //Language code set 조회
};