export default {
    S0000: "S0000",        //정상

    /* User Result Code */
    U1001: "U1001",        //사용자 이름과 비밀번호가 일치하지 않습니다.
    U1002: "U1002",        //동일한 사용자가 존재합니다.
    U1003: "U1003",        //입력한 코드가 일치하지 않습니다
    U1004: "U1004",        //연속된 로그인 실패로 계정이 block 되었습니다.
    U1005: "U1005",        //이메일 미인증 사용자입니다.
    U1006: "U1006",        //유효하지 않은 비밀번호입니다.

    /* Error Result Code */
    E4001: "E4001",        //요청한 API 주소가 존재하지 않습니다.
    E4002: "E4002",        //잘못된 요청입니다.
    E4003: "E4003",        //서버로직 처리중 에러가 발생했습니다.
    E4004: "E4004",        //통신 중 에러가 발생했습니다.
    E4005: "E4005",        //파일 사이즈를 초과했습니다.
    E4006: "E4006",        //외래키 제약조건을 위배했습니다.
    E4007: "E4007",        //인증되지 않은 사용자 입니다.

    E5001: "E5001",        //알 수 없는 오류

    E8001: "E8001",        //실패했습니다.
    E8002: "E8002",        //DB 사용 오류입니다.
    E8003: "E8003",        //잘못된 토큰입니다.

    E8010: "E8010",        //데이터가 존재하지 않습니다.
    E8011: "E8011",        //요청하신 정보는 존재하지 않습니다.
  };
