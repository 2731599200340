//Home
export const HOME = "/";

//LG Response Process
export const PROCESS = "/process";

//LG Security Bulletin
export const BULLETINS = "/bulletins";

// TV
export const BULLETINS_TV = "/bulletins/tv";
export const BULLETINS_TV_SECURITYPOLICY = "/bulletins/tv#securityBulletins";
export const BULLETINS_TV_UPDATEDETAIL = "/bulletins/tv#updateDetails";

// ThinQ
export const BULLETINS_THINQ = "/bulletins/thinq";
export const BULLETINS_THINQ_SECURITYPOLICY = "/bulletins/thinq#securityBulletins";
export const BULLETINS_THINQ_UPDATEDETAIL = "/bulletins/thinq#updateDetails";

// PC
export const BULLETINS_PC = "/bulletins/pc";
export const BULLETINS_PC_SECURITYPOLICY = "/bulletins/pc#securityBulletins";
export const BULLETINS_PC_UPDATEDETAIL = "/bulletins/pc#updateDetails";

// Monitor & Project
export const BULLETINS_MONITORPROJECTOR = "/bulletins/monitorprojector";
export const BULLETINS_MONITORPROJECTOR_SECURITYPOLICY = "/bulletins/monitorprojector#securityBulletins";
export const BULLETINS_MONITORPROJECTOR_UPDATEDETAIL = "/bulletins/monitorprojector#updateDetails";

// ID products
export const BULLETINS_IDPRO = "/bulletins/idproducts";
export const BULLETINS_IDPRO_SECURITYPOLICY = "/bulletins/idproducts#securityBulletins";
export const BULLETINS_IDPRO_UPDATEDETAIL = "/bulletins/idproducts#updateDetails";

// AV products
export const BULLETINS_AVPRO = "/bulletins/avproducts";
export const BULLETINS_AVPRO_SECURITYPOLICY = "/bulletins/avproducts#securityBulletins";
export const BULLETINS_AVPRO_UPDATEDETAIL = "/bulletins/avproducts#updateDetails";

// Home Appliance
export const BULLETINS_HOMEAPPLIANCE = "/bulletins/homeappliance";
export const BULLETINS_HOMEAPPLIANCE_SECURITYPOLICY = "/bulletins/homeappliance#securityBulletins";
export const BULLETINS_HOMEAPPLIANCE_UPDATEDETAIL = "/bulletins/homeappliance#updateDetails";

// webOS HUB product(WEE)
export const BULLETINS_HUBPRO = "/bulletins/weboshubproducts";
export const BULLETINS_HUBPRO_SECURITYPOLICY = "/bulletins/weboshubproducts#securityBulletins";
export const BULLETINS_HUBPRO_UPDATEDETAIL = "/bulletins/weboshubproducts#updateDetails";

// Mobile
export const BULLETINS_MOBILE = "/bulletins/mobile";
export const BULLETINS_MOBILE_SECURITYPOLICY = "/bulletins/mobile#securityBulletins";
export const BULLETINS_MOBILE_UPDATEDETAIL = "/bulletins/mobile#updateDetails";
export const BULLETINS_MOBILE_AERINFORMATION = "/bulletins/mobile#aerInformation";

// Security Reporting
export const REPORTING = "/reporting";
export const CREATE_REPORTING = "/create";

// Rewards Program
export const REWARDS = "/rewards";

// Login
export const LOGIN = "/login";
export const SIGN_UP = "/signup";

// Temrs & Condition
export const TERMS_CONDITION = "/terms";

// Privacy Policy
export const PRIVACY_POLICY = "/privacy";

// My Issue Status Test
export const MYISSUES = "/myissues";

// Report Submit
export const REPORT_SUBMIT = "/submit";