/* login user */
export const IS_LOGIN = "IS_LOGIN";
export const SIGNUP = "SIGNUP";
export const SIGNIN = "SIGNIN";
export const LOGOUT = "LOGOUT";
export const EMAILCODE = "EMAILCODE";
export const RESENDCONFCODE = "RESENDCONFCODE";
export const CHANGEPW = "CHANGEPW";
export const RESETPW = "RESETPW";
export const UPDATEPW = "UPDATEPW";
export const NEWTOKEN = "NEWTOKEN";
export const DELETEUSER = "DELETEUSER";

/* Bulletins */
export const BLT_TYPE_SELECT = "BLT_TYPE_SELECT";
export const BLT_ALL_SMR_SELECT = "BLT_ALL_SMR_SELECT";

/* Report */
export const SELECT_RPT = "SELECT_RPT";
export const INSERT_RPT = "INSERT_RPT";
export const SELECT_RPT_STATE = "SELECT_RPT_STATE";

/* Label */
export const SELECT_LB_SET = "SELECT_LB_SET";

export const SELECT_LANG_CD_SET = "SELECT_LANG_CD_SET";
