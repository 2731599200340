import axios from "axios";
import apiURL from "navigation/apiURL";

axios.defaults.withCredentials = true; //To get cookie data

export const request = (url, data) => {
  return axios
    .post(apiURL.DOMAIN + url, data, {
      
    })
    .then((res) => res)
    .catch((err) => console.log(err) );
  };