import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import ThemeContext from "./theme-context";
import { Cookies } from 'react-cookie';
import AuthRoute from "utils/components/AuthRoute";
import Header from "./components/Header";
import HeaderTop from "./components/HeaderTop";
import Footer from "./components/Footer";

import { HOME, PROCESS, BULLETINS, REPORTING, REWARDS, LOGIN, 
  SIGN_UP, MYISSUES, CREATE_REPORTING, TERMS_CONDITION, 
  PRIVACY_POLICY, REPORT_SUBMIT } from "navigation/routes";

const Home = lazy(() => import("pages/home/Home"));
const Process = lazy(() => import("pages/process/Process"));
const Bulletins = lazy(() => import("pages/bulletins/Bulletins"));
const Reporting = lazy(() => import("pages/reporting/Reporting"));
const CreateReporting = lazy(() => import("pages/reporting/CreateReporting"));
const Rewards = lazy(() => import("pages/rewards/Rewards"));
const Login = lazy(() => import("pages/login/Login"));
const SignUp = lazy(() => import("pages/login/signUp/SignUp"));
const MyIssues = lazy(() => import("pages/myissues/MyIssues"));
const TermsCondition = lazy(() => import("pages/terms/TemrsCondition"));
const PrivacyPolicy = lazy(() => import("pages/terms/PrivacyPolicy"));
const ReportSubmit = lazy(() => import("pages/submit/ReportSubmit"));

function RouterConfig() {
  const [theme, setTheme] = useState("light");
  const [isLogin, setIsLogin] = useState(false);
  const [langData, setLangData] = useState([]);
  const value = { theme, setTheme };
  const cookies = new Cookies();

  useEffect(() => {
    let loginInfo = cookies.get("userInfo");

    if (loginInfo) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  return (
    <>
      <ThemeContext.Provider value={value}>
        <Header isLogin={isLogin} setIsLogin={setIsLogin} langData={langData} setLangData={setLangData}/>
        <HeaderTop isLogin={isLogin} setIsLogin={setIsLogin}/>
        <Suspense fallback={<div></div>}>
          <Switch>
            <AuthRoute
              exact
              path={HOME}
              isLoginPage={false}
              isLogin={isLogin}
              render={() => <Home setIsLogin={setIsLogin}/>}
            />
            <AuthRoute
              exact
              path={PROCESS}
              isLoginPage={false}
              isLogin={isLogin}
              render={() => <Process setIsLogin={setIsLogin}/>}
            />
            <AuthRoute
              exact
              path={REPORTING}
              isLoginPage={false}
              isLogin={isLogin}
              render={() => <Reporting setIsLogin={setIsLogin}/>}
            />
            <AuthRoute
              exact
              path={CREATE_REPORTING}
              isLoginPage={true}
              isLogin={isLogin}
              render={() => <CreateReporting setIsLogin={setIsLogin}/>}
            />
            <AuthRoute
              exact
              path={REWARDS}
              isLoginPage={false}
              isLogin={isLogin}
              render={() => <Rewards setIsLogin={setIsLogin}/>}
            />
            <Route path={BULLETINS} isLoginPage={false} isLogin={isLogin} render={() => <Bulletins setIsLogin={setIsLogin}/>}/>
            <Route
              exact
              path={LOGIN}
              render={() => <Login isLogin={isLogin} setIsLogin={setIsLogin}/>}
            />

            <Route path={SIGN_UP} render={() => <SignUp setIsLogin={setIsLogin}/>}/>

            <AuthRoute
              exact
              path={MYISSUES}
              isLoginPage={true}
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              render={() => <MyIssues setIsLogin={setIsLogin}/>}
            />

            <AuthRoute
              exact
              path={TERMS_CONDITION}
              isLoginPage={false}
              isLogin={isLogin}
              render={() => <TermsCondition setIsLogin={setIsLogin}/>}
            />

            <AuthRoute
              exact
              path={PRIVACY_POLICY}
              isLoginPage={false}
              isLogin={isLogin}
              render={() => <PrivacyPolicy setIsLogin={setIsLogin}/>}
            />

            <AuthRoute
              exact
              path={REPORT_SUBMIT}
              isLoginPage={true}
              isLogin={isLogin}
              render={() => <ReportSubmit setIsLogin={setIsLogin}/>}
            />
          </Switch>
        </Suspense>
        <Footer langData={langData} setLangData={setLangData}/>
      </ThemeContext.Provider>
    </>
  );
}

export default RouterConfig;
