import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import langEn from "./lang.en.json";
import langPt from "./lang.pt.json";
import langKo from "./lang.ko.json";
// import langEs from "./lang.es.json";


const resource = {
  "en": {
    translation: langEn,
  },
  "pt": {
    translation: langPt,
  },
   "ko-KR": {
     translation: langKo,
   },
  // "es-ES": {
  //   translation: langEs,
  // },
};

const userLanguage = window.navigator.language || window.navigator.userLanguage;  //IE supports
let strArray = userLanguage.split('-');

i18n.use(initReactI18next).init({
  resources: resource,
  /* Set default language */
  /* lng: "ko-KR", */
  lng: localStorage.getItem("language") || strArray[0] || "en",
  fallbackLng: {
    /* "pt-PT": ["pt-PT"],
    "en-US": ["en-US"], */
    default: ["en"],
  },
  debug: true,
  defaultNS: "translation",
  ns: "translation",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
