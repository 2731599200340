import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import RouterConfig from '../navigation/RouterConfig';
import i18n from "lang/i18n";
import { store } from "redux/store";
import Aos from "aos";
import * as LangUtils from "lang/LangUtils";

function Root() {
  Aos.init();
  if(!localStorage.getItem("language")){
    localStorage.setItem("language", LangUtils.selectLangCd());
  } 

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <RouterConfig />
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  );
}

export default Root;