export function selectLangCd() { //edge -> ko //chrome -> ko-KR
    let userLang = localStorage.getItem("language") || "en";
    let strArray = userLang.split('-');
    let _langCode = "";

    _langCode = strArray[0];

    return _langCode;
}

export function selectLangCdToLangText(langData) { //edge -> ko //chrome -> ko-KR
    let userLang = localStorage.getItem("language") || "en";
    let strArray = userLang.split('-');
    let _langText = "";

    userLang = strArray[0];

    if(langData.length === 0) {
        return "English";
    }

    langData.map((data)=>{
        if(userLang === data.langCd) {
            _langText = data.langText;
        }
    });

    return _langText;
}