import { SIGNIN } from "redux/actionTypes";
import rstCd from "utils/resultCode";

let loginData = {};

export const loginReducer = (state = loginData, action) => {
  switch (action.type) {
    case SIGNIN:
      if(action.payload === undefined) {
        return loginData;
      } else if(action.payload.data.resultCode === rstCd.S0000) {
        let response = action.payload.data.res;
        return response;
      } else {
        return loginData;
      }
    default:
      return state;
  }
};