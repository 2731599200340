import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { HOME, PROCESS, BULLETINS, REPORTING, REWARDS, MYISSUES, CREATE_REPORTING, REPORT_SUBMIT } from "navigation/routes";
import { useTranslation } from "react-i18next";
import { Cookies } from 'react-cookie';
import { LogOut } from "redux/action";
import rstCd from "utils/resultCode";

import "./Header.scss";

function HeaderTop(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const cookies = new Cookies();
  const [email, setEmail] = useState(""); //user email
  const [userName, setUserName] = useState(""); //user name
  const [userToken, setUserToken] = useState("");
  let scrollTop = 0;

  useEffect(() => {
    let nav_menu = document.getElementsByClassName('header-top-navbar');

    nav_menu[0].classList.add('nav-scrolled-hide');

    if(props.isLogin) {
      let loginInfo = cookies.get("userInfo").userInfo;
      let userEmail = loginInfo.email;

      setEmail(userEmail);
      getUserName(userEmail);
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      scrollTop = 0;
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if(props.isLogin) {
      let loginInfo = cookies.get("userInfo").userInfo;

      let userEmail = loginInfo.email;
      let userAccessToken = loginInfo.accessToken;
      setUserToken(userAccessToken);
      setEmail(userEmail);
      getUserName(userEmail);
    }
  }, [props.isLogin]);

  let getUserName=(email)=> {
    let text = email.substr(0, 2);
    setUserName(text);
  };

  const onClickPath=(_path)=> {
    history.push({
      pathname: _path,
      state: { path: _path },
    });
  };

  const signOutOnClick=()=> {
    dispatch(
      LogOut({
          accessToken: userToken,
          userId: email
        })
    ).then((response)=> {
        let resCode = response.payload.data.resultCode;
        if(response.payload && resCode === rstCd.S0000) {
          //var data = response.payload.data.res;
          props.setIsLogin(false);
          cookies.remove('userInfo');

          if(history.location.pathname === MYISSUES || history.location.pathname === CREATE_REPORTING || history.location.pathname === REPORT_SUBMIT) {
            history.push(HOME);
          }
        } else {
          if(response.payload && resCode === rstCd.E8003) {
            props.setIsLogin(false);
            cookies.remove('userInfo');

            if(history.location.pathname === MYISSUES || history.location.pathname === CREATE_REPORTING || history.location.pathname === REPORT_SUBMIT) {
              history.push(HOME);
            }
          } else {
            alert(response.payload.data.message);
          }
        }
    })
  };

  const onScroll=()=> {
    let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
    let nav_menu = document.getElementsByClassName('header-top-navbar');
    
    if ((currentPosition > scrollTop) || (currentPosition <= scrollTop && currentPosition < 93)) {
      // downscroll code
      nav_menu[0].classList.remove('nav-scrolled-hide');
      nav_menu[0].classList.remove('nav-scrolled-up');
      nav_menu[0].classList.add('nav-scrolled-down');
    } else {
      // upscroll code
      nav_menu[0].classList.remove('nav-scrolled-hide');
      nav_menu[0].classList.remove('nav-scrolled-down');
      nav_menu[0].classList.add('nav-scrolled-up');
      
      let headerTopDpMenu = document.getElementById('header-top-dropdown-menu');
      if(headerTopDpMenu) {
        document.getElementById('header-top-dropdown-menu').classList.remove('show');
      }
    }

    scrollTop = (currentPosition <= 0 ? 0 : currentPosition);
  };
  
  return (
    <nav className="header-top-navbar navbar fixed-top navbar-expand-lg navbar-dark">
      <div className="container">
        <div className="row">
          <div className="col">
            <a /* href={PROCESS} */ onClick={()=>onClickPath(PROCESS)} className="header-top-text font-weight-bold">
              {t("response-process")}
            </a>
          </div>
          <div className="col">
            <a /* href={BULLETINS} */ onClick={()=>onClickPath(BULLETINS)} className="header-top-text font-weight-bold">
              {t("security-bulletins")}
            </a>
          </div>
          <div className="col">
            <a /* href={REPORTING} */ onClick={()=>onClickPath(REPORTING)} className="header-top-text font-weight-bold">
              {t("security-reporting")}
            </a>
          </div>
          <div className="col">
            <a /* href={REWARDS} */ onClick={()=>onClickPath(REWARDS)} className="header-top-text font-weight-bold">
              {t("rewards-program")}
            </a>
          </div>
          {props.isLogin ? 
            <div className="dropdown headerTop-user-dropdown">
              <button className="avatar-circle" id="userDropdownMenuButton" data-toggle="dropdown">{userName}</button>
              <div className="dropdown-menu" id="header-top-dropdown-menu" aria-labelledby="userDropdownMenuButton">
                <a className="dropdown-item" onClick={()=>history.push({pathname: MYISSUES, state: {path : MYISSUES}})}>{t("my-issues")}</a>
                <a className="dropdown-item" onClick={signOutOnClick}>{t("sign-out")}</a>
              </div>
            </div>
            : 
            null
          }
        </div>
      </div>
    </nav>
  );
}

export default HeaderTop;