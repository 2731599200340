import React from "react";
import { Redirect, Route } from "react-router";
import { Cookies } from 'react-cookie';

/* axios */
import { NewToken } from "redux/action";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { LOGIN } from "navigation/routes";
import rstCd from "utils/resultCode";

function AuthRoute(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const cookies = new Cookies();
  let loginInfo = cookies.get("userInfo") ? cookies.get("userInfo").userInfo : undefined;

  function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }

  if (props.isLogin && loginInfo) {
    const currentTime = Math.floor(new Date() / 1000);

    if (currentTime >= Number(loginInfo.accTokExpTime)) {
      //the expiry date is coming -> refresh

      dispatch(
        NewToken({
          userId: loginInfo.email,
          accessToken: loginInfo.accessToken,
        })
      ).then((response)=> {
          let resCode = response.payload.data.resultCode;
          if(response.payload && resCode === rstCd.S0000) {
            let data = response.payload.data.res.accessToken;
            let obj = {};
            let d = new Date();

            data = parseJwt(data);
            d.setMinutes(d.getMinutes()+90);
            
            obj.email = loginInfo.email;
            obj.accTokExpTime = data.exp;
            obj.accessToken = loginInfo.accessToken;
            obj.nbrFldSignIn = loginInfo.nbrFldSignIn;
            obj.nbrNotChgPwd = loginInfo.nbrNotChgPwd;
            
            cookies.set('userInfo', {"userInfo": obj}, {path: '/', expires: d})
          }
      })
    }
  }

  if (props.isLoginPage && loginInfo === undefined) return (<Redirect to={{ pathname: LOGIN, state: { path: props.path }}}/>);
  //if (!props.isLogin) return (<Redirect to={{ pathname: LOGIN, state: { path: props.path }}}/>);

  return <Route {...props} />;
}

export default AuthRoute;
