import {
    SIGNUP, SIGNIN, LOGOUT, EMAILCODE, CHANGEPW, RESETPW, UPDATEPW, NEWTOKEN, DELETEUSER,
    BLT_TYPE_SELECT, BLT_ALL_SMR_SELECT, SELECT_RPT, INSERT_RPT, SELECT_RPT_STATE, SELECT_LB_SET, RESENDCONFCODE, SELECT_LANG_CD_SET
} from "./actionTypes";
import { request } from "utils/axios";
import apiURL from "navigation/apiURL";


/* 회원 가입 */
export function SignUp(dataToSubmit) {
    const data = request(apiURL.SIGN_UP, dataToSubmit);

    return {
        type: SIGNUP,
        payload: data
    };
}

/* 로그인 */
export function SignIn(dataToSubmit) {
    const data = request(apiURL.SIGN_IN, dataToSubmit);

    return {
        type: SIGNIN,
        payload: data
    };
}

/* 로그아웃 */
export function LogOut(dataToSubmit) {
    const data = request(apiURL.LOG_OUT, dataToSubmit);

    return {
        type: LOGOUT,
        payload: data
    };
}

/* 이메일 확인코드 인증 */
export function EmailCode(dataToSubmit) {
    const data = request(apiURL.EMAIL_CODE, dataToSubmit);

    return {
        type: EMAILCODE,
        payload: data
    };
}

/* 이메일 확인코드 인증 재요청*/
export function ResendConfCode(dataToSubmit) {
    const data = request(apiURL.RESEND_CONF_CODE, dataToSubmit);

    return {
        type: RESENDCONFCODE,
        payload: data
    };
}

/* 이메일 변경 요청 */
export function ChangePw(dataToSubmit) {
    const data = request(apiURL.CHANGE_PW, dataToSubmit);

    return {
        type: CHANGEPW,
        payload: data
    };
}

/* 비밀번호 초기화 요청 */
export function ResetPw(dataToSubmit) {
    const data = request(apiURL.RESET_PW, dataToSubmit);

    return {
        type: RESETPW,
        payload: data
    };
}

/* 비밀번호 초기화 업데이트 */
export function UpdatePw(dataToSubmit) {
    const data = request(apiURL.UPDATE_PW, dataToSubmit);

    return {
        type: UPDATEPW,
        payload: data
    };
}

/* Token 재발급 */
export function NewToken(dataToSubmit) {
    const data = request(apiURL.NEW_TOKEN, dataToSubmit);

    return {
        type: NEWTOKEN,
        payload: data
    };
}

/* 회원 탈퇴 */
export function DeleteUser(dataToSubmit) {
    const data = request(apiURL.DELETE_USER, dataToSubmit);

    return {
        type: DELETEUSER,
        payload: data
    };
}

/* Bulletins 타입 리스트 조회  */
export function BltTypeListSelect(dataToSubmit) {
    const data = request(apiURL.BLT_TYPE_LIST_SELECT, dataToSubmit);

    return {
        type: BLT_TYPE_SELECT,
        payload: data
    };
}

/* Bulletins SMR 전체 리스트 조회  */
export function BltSmrAllListSelect(dataToSubmit) {
    const data = request(apiURL.BLT_ALL_SMR, dataToSubmit);

    return {
        type: BLT_ALL_SMR_SELECT,
        payload: data
    };
}

/* Report 조회  */
export function SelectReport(dataToSubmit) {
    const data = request(apiURL.SELECT_REPORT, dataToSubmit);

    return {
        type: SELECT_RPT,
        payload: data
    };
}

/* Report 추가  */
export function InsertReport(dataToSubmit) {
    const data = request(apiURL.INSERT_REPORT, dataToSubmit);

    return {
        type: INSERT_RPT,
        payload: data
    };
}

/* Report State 조회  */
export function SelectReportState(dataToSubmit) {
    const data = request(apiURL.SELECT_REPORT_STATE, dataToSubmit);

    return {
        type: SELECT_RPT_STATE,
        payload: data
    };
}

/* Label Set 조회  */
export function SelectLabelSet(dataToSubmit) {
    const data = request(apiURL.SELECT_LABEL_SET, dataToSubmit);

    return {
        type: SELECT_LB_SET,
        payload: data
    };
}


/* Language code set 조회  */
export function SelectLangCdSet(dataToSubmit) {
    const data = request(apiURL.SELECT_LANG_CD_SET, dataToSubmit);

    return {
        type: SELECT_LANG_CD_SET,
        payload: data
    };
}
